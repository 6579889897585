@import "_global";

body {
  background-color: $primary-color;
  color: $primary-font-color;
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
  img {
    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}
}

@media only screen and (min-width: 1001px) {
  div#content-container {
    width: 100%;
    height: 100%;
    padding: 80px 70px 0 70px;
    box-sizing: border-box;
  }
}

@media only screen and (max-width: 1000px) {
    div#content-container {
      width: 100%;
      height: 100%;
      padding: 20px 20px 0 20px;
      box-sizing: border-box;
    }
  }
  