div#loading {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    img#clock {
        width: 100px;
        height: 100px;
        transform: translate(-50%, -50%);
        animation-name: rotateClock;
        animation-duration: 1s;
        animation-iteration-count: infinite;
        transform-origin: center;
    }

    @keyframes rotateClock {
        from {
            transform: rotate(0);
        }
        to {
            transform: rotate(360deg);
        }
    }
}
