@import "../../styles/_global";

$menu-primary-color: black;

menu#DesktopMenuBar {
  z-index: 5000;
  width: 100%;
  height: 50px;
  position: fixed;
  background-color: $menu-primary-color;
  margin: 0;
  padding: 10px 50px 10px 50px;
  box-sizing: border-box;

  a#logo {
    position: relative;
    top: 5px;
    text-decoration: none;
    color: white;
    font-size: 18px;
  }

  button#menu-btn {
    display: none;
  }

  nav {
    display: block;
    float: right;

    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
      li {
        display: inline-flex;
        flex-direction: row;

        a {
          color: $primary-font-color;
          display: block;
          padding: 8px;
          margin: 0 8px 0 8px;
          text-decoration: none;
          font-size: 15px;
        }

        a:hover {
          color: rgb(190, 190, 190);
        }
      }
    }
  }

  div.dropdown-btn-container {
    position: relative;
    width: 100%;
    top: 7px;
    height: 33px;

    span {
      margin: 0 10px;
      height: 100%;
      font-size: 14px;
    }

    span:hover {
      color:rgb(190, 190, 190);
    }

    menu.dropdown {
      display: none;
      top: 17px;
      position: absolute;
      right: 0;
      width: 100px;
      height: 100px;
      background-color: $menu-primary-color;

      ul.menu-selection {
        width: 100%;
        height: 100%;
        list-style-type: none;
        margin: 0;
        padding: 0;
        position: absolute;
        left: 0;

        li {
          display: flex;
          flex-direction: column;
          
          a {
            width: 100%;
            height: 35px;
            padding: 8px 10px 8px 10px;
            box-sizing: border-box;
            color: $primary-font-color;
            display: block;
            text-decoration: none;
            font-size: 14px;
            border-bottom: 0.1px solid $primary-font-color;
            margin: 0;
          }

          a:hover {
            background-color: $primary-font-color;
            color: $primary-color;
            cursor: pointer;
          }
        }
      }
    }
  }

  div.dropdown-btn-container#user {
    float: right;
    width: 30px;

    button#setting-btn {
      background: none;
      border: none;
      float: right;
      margin: 0;

      img {
        filter: invert(100%);
        width: 17px;
      }
    }

    button#setting-btn:hover {
      img {
        filter: invert(80%);
      }
    }
  }

  div.dropdown-btn-container#routine {
    top: 0px;
  }

  div.dropdown-btn-container#event {
    top: 0px;
  }

  div.dropdown-btn-container:hover menu.dropdown {
    display: block;
  }
}

menu#MobileMenuBar {
  z-index: 5000;
  width: 100%;
  height: 60px;
  background-color: $menu-primary-color;
  margin: 0;
  padding: 10px 50px 10px 50px;
  box-sizing: border-box;
  position: fixed;
  bottom: 0;
}
